<header
  class="header"
  [ngClass]="{ 'header-box-shadow': router.url.startsWith('/signup') }">
  <img
    *ngIf="router.url.startsWith('/signup')"
    src="assets/img/Tongo_logo_new.svg"
    class="p-header__logo"
    alt="Tongo Logo" />
  <a
    mat-raised-button
    color="primary"
    *ngIf="
      router.url.startsWith('/login') &&
      !(authService.authUser | async)?.partnerId
    "
    routerLink="/signup"
    class="btn btn-link header-center-item"
    >Sign up</a
  >
  <a
    mat-raised-button
    *ngIf="
      router.url.startsWith('/signup') &&
      !(authService.authUser | async)?.partnerId
    "
    color="primary"
    routerLink="/login"
    class="btn btn-link header-center-item"
    >Sign in</a
  >
</header>
